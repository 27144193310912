
import {
  alertController,
  IonPage,
  IonContent,
  loadingController,
  IonInput,
  IonButton,
  IonBackButton,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Recover",
  /**
   * components
   */
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonButton,
    IonBackButton,
    IonToolbar,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const screenName = "PAGE-RECOVER";
    const analytics = new Analytics();
    console.log(store);

    // state
    const state = reactive({
      form: {
        email: "",
      },
    });

    const presentAlert = async (message: any, callback: any = null) => {
      const alert = await alertController.create({
        header: `${t("notification")}`,
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: `${t("check")}`,
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `${t("screen.auth.recover.looking")}`,
      });
      await loading.present();

      store
        .dispatch("recover", {
          email: state.form.email,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            loading.dismiss();
            presentAlert(`${t("screen.auth.recover.sentEmail")}`, async () => {
              window.history.length > 1 ? router.go(-1) : router.push("/");
            });
          }
        })
        .catch((message) => {
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const onLogin = async () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    onMounted(() => {
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      t,
      router,
      onSubmit,
      onLogin,
      state,
    };
  },
});
